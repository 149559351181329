import React from 'react'

import { Paper } from '@mui/material'

import style from './Hostel.module.scss'

import { HostelType } from 'api/types'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

type HostelCT = {
  hostel: HostelType
}

export const Hostel: React.FC<HostelCT> = ({ hostel }) => {
  const lang = useAppSelector(language)

  return (
    <div className={style.hostelContainer}>
      <Paper square elevation={9}>
        <img src={hostel.photo} alt="" />
      </Paper>
      <a href={hostel.link} className={style.hostelTitle} target={'_blank'} rel="noreferrer">
        {lang === 'ru' ? hostel.title : hostel.title_en}
      </a>
      <p className={style.hostelText}>{lang === 'ru' ? hostel.content1 : hostel.content1_en}</p>
      <p className={style.hostelText}>{lang === 'ru' ? hostel.content2 : hostel.content2_en}</p>
      <p className={style.hostelText}>{lang === 'ru' ? hostel.content3 : hostel.content3_en}</p>
    </div>
  )
}
