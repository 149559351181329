import React from 'react'

import Paper from '@mui/material/Paper/Paper'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import style from './AboutUsHeader.module.scss'

import left1 from 'components/c2-Body/b2-AboutUs/img/left1.jpeg'
import arrowL from 'components/c2-Body/img/arrows/FFF/ArrowL.svg'
import arrowR from 'components/c2-Body/img/arrows/FFF/ArrowR.svg'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const AboutUsHeader = () => {
  const lang = useAppSelector(language)
  const settings = {
    infinite: true,
    speed: 2000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
  }
  const navigate = useNavigate()
  const aboutUsContent = useAppSelector(state => state.admin.aboutUs)
  let content = { __html: lang === 'ru' ? aboutUsContent.content : aboutUsContent.content_en }
  const arrPhotos = aboutUsContent.photos

  return (
    <div className={style.aboutUsHeader}>
      <div className={style.imgBlock_left}>
        <img src={left1} alt="" />
      </div>
      <Paper
        style={{
          width: '90%',
          maxWidth: '800px',
          margin: '40px auto 60px',
        }}
        square
        elevation={9}
      >
        <Slider {...settings}>
          {arrPhotos.map((e, index) => {
            const backgroundImage = {
              backgroundImage: `url(${e})`,
            }

            return (
              <div key={index}>
                <div className={style.slide} style={backgroundImage} />
              </div>
            )
          })}
        </Slider>
        <div className={style.container}>
          <div dangerouslySetInnerHTML={content} />
          <div className={style.container__buttons}>
            <button
              className={style.container__buttons_questions}
              onClick={() => {
                navigate('/FAQ')
                window.scrollTo(0, 0)
              }}
            >
              {lang === 'ru' ? 'Вопросы о проживании' : 'Residential Matters'}
            </button>
            <button
              className={style.container__buttons_contacts}
              onClick={() => {
                navigate('/contact')
                window.scrollTo(0, 0)
              }}
            >
              {lang === 'ru' ? 'Контакты' : 'Contacts'}
            </button>
          </div>
        </div>
      </Paper>
    </div>
  )
}
export const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: '32px',
        height: '50px',
        content: `url(${arrowR})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        right: '30px',
      }}
      onClick={onClick}
    />
  )
}

export const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: '32px',
        height: '50px',
        content: `url(${arrowL})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        zIndex: 10,
        left: '30px',
      }}
      onClick={onClick}
    />
  )
}
