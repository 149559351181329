import React, { useEffect } from 'react'

import style from './RoomsBlock.module.scss'

import { roomsTC } from 'api/bll/adminReduser'
import left1 from 'components/c2-Body/b2-AboutUs/img/left1.jpeg'
import { RoomCard } from 'components/c2-Body/b3-Rooms/RoomsBlock/RoomCard/RoomCard'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const RoomsBlock = () => {
  const lang = useAppSelector(language)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(roomsTC())
  }, [])

  const arrRooms = useAppSelector(state => state.admin.rooms)

  return (
    <div className={style.roomsBlock}>
      <div className={style.imgBlock_left}>
        <img src={left1} alt="" />
      </div>
      <HeaderBlock title={lang === 'ru' ? 'НОМЕРА' : 'ROOMS'} after={'#f3b246'} width={'20%'} />
      <div className={style.roomsBlock_container}>
        {arrRooms.map(room => (
          <RoomCard key={room.id} room={room} />
        ))}
      </div>
    </div>
  )
}
