import React from 'react'

import style from './Contact.module.scss'
import { Documents } from './Documents/Documents'
import { HeaderContacts } from './HeaderContacts/HeaderContacts'
import { HouToGet } from './HouToGet/HouToGet'

export const Contact = () => {
  return (
    <div className={style.contact}>
      <HeaderContacts />
      <HouToGet />
      <Documents />
    </div>
  )
}
