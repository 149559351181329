import React, { useEffect } from 'react'

import Slider from 'react-slick'

import style from './Shares.module.scss'

import { sharesTC } from 'api/bll/adminReduser'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { SampleNextArrow, SamplePrevArrow } from 'components/common/Slider/SliderArrows'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const Shares = () => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(language)

  useEffect(() => {
    dispatch(sharesTC())
  }, [])

  const arrShares = useAppSelector(state => state.admin.shares)

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    //adaptiveHeight: true,
  }

  return (
    <div id="shares" className={style.shares}>
      <HeaderBlock
        title={lang === 'ru' ? 'АКЦИИ' : 'PROMOTIONS'}
        before={'#3f5a69'}
        after={'#3f5a69'}
        width={'25%'}
      />
      <div className={style.slider}>
        <Slider {...settings}>
          {arrShares &&
            arrShares.map((share, index) => {
              const backgroundImage = {
                backgroundImage: `url(${share.photo})`,
              }

              return (
                <div key={index} className={style.sliderPicture}>
                  <div style={backgroundImage}>
                    <span>{lang === 'ru' ? share.title : share.title_en}</span>
                    <p>{lang === 'ru' ? share.share : share.share_en}</p>
                  </div>
                </div>
              )
            })}
        </Slider>
      </div>
    </div>
  )
}
