import React from 'react'

import style from 'components/common/HeaderBlock/HeaderBlock.module.scss'

type HeaderCT = {
  title: string
  text?: string
  before?: string
  after?: string
  width?: string
}

export const HeaderBlock: React.FC<HeaderCT> = ({ title, text, before, after, width }) => {
  const lineBefore = {
    background: before,
    width: width,
  }
  const lineAfter = {
    background: after,
    width: width,
  }

  return (
    <div className={style.header}>
      <div className={style.title}>
        <div style={lineBefore} className={style.lineBefore}></div>
        {title}
        <div style={lineAfter} className={style.lineAfter}></div>
      </div>
      <div className={style.text}>{text}</div>
    </div>
  )
}
