import React from 'react'

import { NavLink } from 'react-router-dom'

import { useAppSelector } from '../../hooks/hooks'
import { useElementWidth } from '../../hooks/useElementWidth'
import { language } from '../../utils/selectors'

import style from './Header.module.scss'
import location from './img/map.svg'

import { HeaderMenu, scrollUp } from 'components/c1-Header/h1-Menu/HeaderMenu'
import logo from 'components/c1-Header/h1-Menu/img/logo.svg'
import { Language } from 'components/c1-Header/h2-Language/Language'

export const Header = () => {
  const lang = useAppSelector(language)
  const mainPage = useAppSelector(state => state.admin.mainPage)

  function scrollFunction() {
    const header = document.getElementById('header')

    if (
      (document.body.scrollTop > 54 && document.documentElement.clientWidth > 769) ||
      (document.documentElement.scrollTop > 54 && document.documentElement.clientWidth > 769)
    ) {
      if (header) header.className = style.headerContainer
    } else {
      if (header) header.className = style.headerContainer_active
    }
  }
  window.onscroll = function () {
    scrollFunction()
  }
  const [ref, width] = useElementWidth()

  return (
    <div id={'header'} className={style.headerContainer_active}>
      <div ref={ref} className={style.header}>
        <NavLink onClick={scrollUp} to="/home" className={style.logo}>
          <img src={logo} alt="" />
        </NavLink>
        <div className={style.block}>
          <div className={style.address}>
            <div>
              <img src={location} alt="" />
            </div>
            <div className={style.address_text}>
              {lang === 'ru' ? 'г. Калининград, ул. Гостиная 22' : 'Kaliningrad, Gostinaya st. 22'}
            </div>
          </div>
          <form action={lang === 'ru' ? mainPage.book_link : mainPage.book_link_en} target="_blank">
            <button>{lang === 'ru' ? 'Забронировать номер' : 'Book now'}</button>
          </form>
          <Language />
        </div>
        <HeaderMenu />
        {document.documentElement.clientWidth < 769 ? <Language /> : <div></div>}
      </div>
    </div>
  )
}
