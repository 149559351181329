import React, { useEffect } from 'react'

import { HomeHeader } from './h1-HomeHeader/HomeHeader'
import { Shares } from './h2-Shares/Shares'
import { HomeRooms } from './h3-HomeRooms/HomeRooms'
import { News } from './h4-News/News'
import { WhyUs } from './h5-WhyUs/WhyUs'
import { WhatIsNear } from './h6-WhatIsNear/WhatIsNear'
import { Reviews } from './h7-Reviews/Reviews'
import { HomeFAQ } from './h8-HomeFAQ/HomeFAQ'
import { OurHostels } from './h9-OurHostels/OurHostels'
import style from './Home.module.scss'

import { mainPageTC } from 'api/bll/adminReduser'
import { useAppDispatch } from 'hooks/hooks'

export const Home = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(mainPageTC())
  }, [])

  return (
    <div className={style.home}>
      <HomeHeader />
      <Shares />
      <HomeRooms />
      <News />
      <WhyUs />
      <WhatIsNear />
      <Reviews />
      <HomeFAQ />
      <OurHostels />
    </div>
  )
}
