import React, { useCallback, useState } from 'react'

import Paper from '@mui/material/Paper/Paper'

import style from './FAQItem.module.scss'

import { FAQType } from 'api/types'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

type FAQItemCT = {
  FAQ: FAQType
}

export const FAQItem: React.FC<FAQItemCT> = ({ FAQ }) => {
  const lang = useAppSelector(language)
  const [displayFAQList, setDisplayFAQList] = useState(false)
  const drop = useCallback(() => setDisplayFAQList(prevState => !prevState), [setDisplayFAQList])
  const displayFAQ = displayFAQList ? `${style.jsRotateArrow}` : `${style.arrowRight}`

  return (
    <Paper>
      <div className={style.FAQItem}>
        <button className={style.dropButton} type={'button'} onClick={drop}>
          <div className={displayFAQ}> {lang === 'ru' ? FAQ.title : FAQ.title_en}</div>
        </button>
        {displayFAQList ? (
          <div className={style.dropItem}>
            {lang === 'ru' ? FAQ.description : FAQ.description_en}
          </div>
        ) : (
          ''
        )}
      </div>
    </Paper>
  )
}
