import React, { useEffect } from 'react'

import Slider from 'react-slick'

import style from './Reviews.module.scss'

import { reviewsTC } from 'api/bll/adminReduser'
import { Review } from 'components/c2-Body/b1-Home/h7-Reviews/Review/Review'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { SampleNextArrow, SamplePrevArrow } from 'components/common/Slider/SliderArrows'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const Reviews = () => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(language)

  useEffect(() => {
    dispatch(reviewsTC())
  }, [])

  const arrReviews = useAppSelector(state => state.admin.reviews)

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
  }

  return (
    <div id="reviews" className={style.reviews}>
      <HeaderBlock
        title={lang === 'ru' ? 'ОТЗЫВЫ' : 'REVIEWS'}
        before={'#3f5a69'}
        after={'#3f5a69'}
        width={'25%'}
      />
      <div className={style.reviewsContainer}>
        <Slider {...settings}>
          {arrReviews && arrReviews.map((review, index) => <Review key={index} review={review} />)}
        </Slider>
      </div>
    </div>
  )
}
