import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { adminAPI } from 'api/api'
import { AboutUsType, FAQType, HostelType, NewsType, ReviewType } from 'api/types'

//THUNKS

const slice = createSlice({
  name: 'language',
  initialState: {
    language: 'ru',
  },
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload
    },
  },
})

export const languageReducer = slice.reducer

export const { setLanguage } = slice.actions
