import React, { useEffect } from 'react'

import style from './OurHostel.module.scss'

import { ourHostelsTC } from 'api/bll/adminReduser'
import { Hostel } from 'components/c2-Body/b1-Home/h9-OurHostels/Hostel/Hostel'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const OurHostels = () => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(language)

  useEffect(() => {
    dispatch(ourHostelsTC())
  }, [])

  const arrHostels = useAppSelector(state => state.admin.ourHostels)

  return (
    <div className={style.ourHostel}>
      <HeaderBlock
        title={lang === 'ru' ? 'НАШИ ХОСТЕЛЫ' : 'OUR HOSTELS'}
        before={'#3f5a69'}
        after={'#3f5a69'}
        width={'25%'}
      />
      <div className={style.ourHostelContainer}>
        {arrHostels && arrHostels.map((hostel, index) => <Hostel key={index} hostel={hostel} />)}
      </div>
    </div>
  )
}
