import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { adminReducer } from 'api/bll/adminReduser'
import { languageReducer } from 'api/bll/languageReduser'
import { newsReducer } from 'api/bll/newsReduser'
import { roomReducer } from 'api/bll/roomReduser'
import { loadState, saveState } from 'utils/sessionStorage-utils'

const rootReducer = combineReducers({
  admin: adminReducer,
  oneNews: newsReducer,
  room: roomReducer,
  language: languageReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(thunkMiddleware),
  preloadedState: loadState(),
})

store.subscribe(() => {
  saveState({
    admin: store.getState().admin,
    oneNews: store.getState().oneNews,
    room: store.getState().room,
    language: store.getState().language,
  })
})

export type AppThunkType<ReturnType = void> = ThunkAction<
  ReturnType,
  AppStateType,
  unknown,
  ActionsType
>

export type AppDispatch = ThunkDispatch<AppStateType, unknown, ActionsType>

export type ActionsType = any
export type AppStateType = ReturnType<typeof rootReducer>
export type AppStoreType = typeof store
// @ts-ignore
window.store = store // for dev
