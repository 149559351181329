import React from 'react'

import Paper from '@mui/material/Paper/Paper'

import style from './ServicesBlock.module.scss'

import { ServiceType } from 'api/types'

type ServicesBlockType = {
  arr: ServiceType[]
}
export const ServicesBlock: React.FC<ServicesBlockType> = ({ arr }) => {
  return (
    <div className={style.servicesBlock_container}>
      {arr.map((service, index) => (
        <div key={index} className={style.servicesBlock}>
          <Paper
            style={{
              width: '100%',
              height: '100%',
            }}
            square
            elevation={9}
          >
            <div className={style.slideContainer}>
              <div className={style.slide}>
                <div className={style.slideImg}>
                  <img src={service.file} alt="" />
                </div>
                <span className={style.slideText}>{service.text}</span>
              </div>
            </div>
          </Paper>
        </div>
      ))}
    </div>
  )
}
