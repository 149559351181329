import React from 'react'

import Slider from 'react-slick'

import { useElementWidth } from '../../../../hooks/useElementWidth'

import dramteatr from './img/dramteatr.jpg'
import muzteatr from './img/muzteatr.jpg'
import park from './img/park.jpg'
import parkpobyedy from './img/parkpobyedy.jpg'
import square from './img/square.jpg'
import zoo from './img/zoo.jpg'
import style from './WhatIsNear.module.scss'
import { WhatIsNearCard } from './WhatIsNearCard/WhatIsNearCard'

import { Booking } from 'components/c2-Body/b1-Home/h6-WhatIsNear/Booking/Booking'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { SampleNextArrow, SamplePrevArrow } from 'components/common/Slider/SliderArrows'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export type PlaceType = {
  img: string
  title: string
  title_en: string
  text: string
  text_en: string
}

const arrPlaces: PlaceType[] = [
  {
    img: zoo,
    title: 'ЗООПАРК',
    title_en: 'ZOO',
    text: '340 метров',
    text_en: '340 meters',
  },
  {
    img: square,
    title: 'ПЛОЩАДЬ ПОБЕДЫ',
    title_en: 'VICTORY SQUARE',
    text: '1,1 километра',
    text_en: '1,1 kilometers',
  },
  {
    img: parkpobyedy,
    title: 'ПАРК ПОБЕДЫ',
    title_en: 'VICTORY PARK',
    text: '600 метров',
    text_en: '600 meters',
  },
  {
    img: muzteatr,
    title: 'МУЗЫКАЛЬНЫЙ ТЕАТР',
    title_en: 'MUSICAL THEATRE',
    text: '1,9 километров',
    text_en: '1,9 kilometers',
  },
  {
    img: park,
    title: 'ЦЕНТРАЛЬНЫЙ ПАРК КУЛЬТУРЫ И ОТДЫХА',
    title_en: 'CENTRAL PARK OF CULTURE',
    text: '1,2 километра',
    text_en: '1,2 kilometers',
  },
  {
    img: dramteatr,
    title: 'КАЛИНИНГРАДСКИЙ ДРАМАТИЧЕСКИЙ',
    title_en: 'KALININGRAD DRAMA THEATRE',
    text: '670 метров',
    text_en: '670 meters',
  },
]

export const WhatIsNear = () => {
  const lang = useAppSelector(language)
  const windowInnerWidth = window.innerWidth
  const [ref, width] = useElementWidth()
  const slidesShowFn = () => {
    let slidesShow = 4

    if (windowInnerWidth <= 1600) {
      slidesShow = 3
    }

    if (windowInnerWidth <= 1280) {
      slidesShow = 2
    }
    if (windowInnerWidth <= 768) {
      slidesShow = 1
    }

    return slidesShow
  }
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesShowFn(),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <div className={style.whatIsNear}>
      <HeaderBlock
        title={lang === 'ru' ? 'ЧТО РЯДОМ?' : 'WHAT IS NEAR'}
        before={'#f3b246'}
        width={'25%'}
      />
      <div ref={ref} className={style.whatIsNearContainer}>
        <Slider {...settings}>
          {arrPlaces.map((place, index) => (
            <WhatIsNearCard key={index} place={place} />
          ))}
        </Slider>
      </div>
      <Booking />
    </div>
  )
}
