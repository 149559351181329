import React from 'react'

import Slider from 'react-slick'

import { useElementWidth } from '../../../../hooks/useElementWidth'

import style from './Gallery.module.scss'

import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { SampleNextArrow, SamplePrevArrow } from 'components/common/Slider/SliderArrows'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const Gallery = () => {
  const lang = useAppSelector(language)
  const windowInnerWidth = window.innerWidth
  const [ref, width] = useElementWidth()
  const slidesShowFn = () => {
    let slidesShow = 4

    if (windowInnerWidth <= 1600) {
      slidesShow = 3
    }

    if (windowInnerWidth <= 1280) {
      slidesShow = 2
    }
    if (windowInnerWidth <= 768) {
      slidesShow = 1
    }

    return slidesShow
  }
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesShowFn(),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  const arrGallery = useAppSelector(state => state.admin.aboutUs.gallery)

  return (
    <div className={style.gallery}>
      <HeaderBlock title={lang === 'ru' ? 'ГАЛЕРЕЯ' : 'GALLERY'} before={'#f3b246'} width={'30%'} />
      <div ref={ref} className={style.galleryContainer}>
        <Slider {...settings}>
          {arrGallery.map((e, index) => {
            const backgroundImage = {
              backgroundImage: `url(${e})`,
            }

            return (
              <div key={index}>
                <div className={style.slide} style={backgroundImage} />
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
