import React, { useState } from 'react'

import style from './HomeHeader.module.scss'
import image1 from './img/1.jpg'
import image2 from './img/2.jpg'
import image3 from './img/3.jpg'

import arrowL from 'components/c2-Body/img/arrows/FFF/ArrowL.svg'
import arrowR from 'components/c2-Body/img/arrows/FFF/ArrowR.svg'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const HomeHeader = () => {
  const lang = useAppSelector(language)
  const arrImages = [image1, image2, image3]

  const mainPage = useAppSelector(state => state.admin.mainPage)

  const [index, setIndex] = useState(0)
  const [image, setImage] = useState(arrImages[index])
  const backgroundImage = {
    backgroundImage: `url(${image})`,
  }
  const inc = () => {
    if (index >= arrImages.length - 1) {
      setIndex(0)
      setImage(arrImages[0])
    } else {
      setIndex(index + 1)
      setImage(arrImages[index + 1])
    }
  }
  const dec = () => {
    if (index < 1) {
      setIndex(arrImages.length - 1)
      setImage(arrImages[arrImages.length - 1])
    } else {
      setIndex(index - 1)
      setImage(arrImages[index - 1])
    }
  }

  return (
    <div style={backgroundImage} className={style.homeHeader}>
      <div className={style.slider}>
        <div className={style.lining}></div>
        <div className={style.textBlock}>
          <h1>{lang === 'ru' ? mainPage.h1 : mainPage.h1_en}</h1>
          <p>{lang === 'ru' ? mainPage.sub_h1 : mainPage.sub_h1_en}</p>
          <form action={lang === 'ru' ? mainPage.book_link : mainPage.book_link_en} target="_blank">
            <button>{lang === 'ru' ? 'Забронировать номер' : 'Book now'}</button>
          </form>
        </div>
        <div className={style.videoBlock}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/n7MQM5cGsw8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className={style.buttons}>
          <button onClick={dec}>
            <img src={arrowL} alt="" />
          </button>
          <button onClick={inc}>
            <img src={arrowR} alt="" />
          </button>
        </div>
      </div>
    </div>
  )
}
