import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { adminAPI } from 'api/api'
import { OneRoomType } from 'api/types'

//THUNKS
export const oneRoomTC = createAsyncThunk('room', async (id: number, { dispatch }) => {
  const res = await adminAPI.getRoom(id)

  dispatch(setRoom(res.data))
})

const slice = createSlice({
  name: 'room',
  initialState: {
    room: {
      id: 1,
      title: '',
      title_en: '',
      description: '',
      description_en: '',
      beds: 1,
      stuff: [''],
      stuff_en: [''],
      photos: [''],
    },
  },
  reducers: {
    setRoom(state, action: PayloadAction<OneRoomType>) {
      state.room = action.payload
    },
  },
})

export const roomReducer = slice.reducer

export const { setRoom } = slice.actions
