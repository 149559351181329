import React from 'react'

import Paper from '@mui/material/Paper/Paper'
import { useNavigate } from 'react-router-dom'

import style from './NewsBlockCard.module.scss'

import { NewsType } from 'api/types'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

type NewsBlockCardCT = {
  news: NewsType
}

export const NewsBlockCard: React.FC<NewsBlockCardCT> = ({ news }) => {
  const image = {
    backgroundImage: `url(${news.photo})`,
  }
  const lang = useAppSelector(language)
  const navigate = useNavigate()
  const goToNews = (id: number) => {
    window.scrollTo(0, 0)
    navigate(`/news/${id}`)
  }

  return (
    <Paper
      style={{
        width: '100%',
        maxWidth: '460px',
        margin: '0 auto 20px',
        height: '100%',
        minHeight: '450px',
        cursor: 'pointer',
      }}
      square
      elevation={9}
      onClick={() => goToNews(news.id)}
    >
      <div className={style.newsContainer}>
        <div className={style.news}>
          <div style={image} className={style.newsImg}>
            {/*<img src={news.photo} alt="" />*/}
          </div>
          <div className={style.newsTitle}>
            {lang === 'ru' ? news.short_description : news.short_description_en}
          </div>
          <div className={style.newsText}>{lang === 'ru' ? news.preview : news.preview_en}</div>
          <span className={style.newsData}>{news.date}</span>
        </div>
      </div>
    </Paper>
  )
}
