import React from 'react'

import './App.css'

import { Navigate, Route, Routes } from 'react-router-dom'

import { Header } from 'components/c1-Header/Header'
import { Home } from 'components/c2-Body/b1-Home/Home'
import { AboutUs } from 'components/c2-Body/b2-AboutUs/AboutUs'
import { Room } from 'components/c2-Body/b3-Rooms/Room/Room'
import { Rooms } from 'components/c2-Body/b3-Rooms/Rooms'
import { Services } from 'components/c2-Body/b4-Services/Services'
import { FAQ } from 'components/c2-Body/b5-FAQ/FAQ'
import { Contact } from 'components/c2-Body/b7-Contact/Contact'
import { NewsBlock } from 'components/c2-Body/News/NewsBlock/NewsBlock'
import { NewsPage } from 'components/c2-Body/News/NewsPage/NewsPage'
import { Visa } from 'components/c2-Body/Visa/Visa'
import { Footer } from 'components/c3-Footer/Footer'

export const App = () => {
  return (
    <div>
      <Header />
      <div className={'headerLine'}></div>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/rooms/:id" element={<Room />} />
        <Route path="/services" element={<Services />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/news" element={<NewsBlock />} />
        <Route path="/visa" element={<Visa />} />
        <Route path="/news/:id" element={<NewsPage />} />
        <Route path="404" element={<h1>404: PAGE NOT FOUND</h1>} />
        <Route path="*" element={<Navigate to="404" />} />
      </Routes>
      <Footer />
    </div>
  )
}
