import React, { useEffect } from 'react'

import Slider from 'react-slick'

import { useElementWidth } from '../../../../hooks/useElementWidth'

import { roomsTC } from 'api/bll/adminReduser'
import { HomeRoom } from 'components/c2-Body/b1-Home/h3-HomeRooms/HomeRoom/HomeRoom'
import style from 'components/c2-Body/b1-Home/h3-HomeRooms/HomeRooms.module.scss'
import left1 from 'components/c2-Body/b2-AboutUs/img/left1.jpeg'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { SampleNextArrow, SamplePrevArrow } from 'components/common/Slider/SliderArrows'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const HomeRooms = () => {
  const lang = useAppSelector(language)
  const dispatch = useAppDispatch()
  const windowInnerWidth = window.innerWidth
  const [ref, width] = useElementWidth()
  const slidesShowFn = () => {
    let slidesShow = 4

    if (windowInnerWidth <= 1600) {
      slidesShow = 3
    }
    if (windowInnerWidth <= 1280) {
      slidesShow = 2
    }
    if (windowInnerWidth <= 768) {
      slidesShow = 1
    }

    return slidesShow
  }

  useEffect(() => {
    dispatch(roomsTC())
  }, [])

  const arrRooms = useAppSelector(state => state.admin.rooms)
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesShowFn(),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <div className={style.homeRooms}>
      <div className={style.imgBlock_left}>
        <img src={left1} alt="" />
      </div>
      <HeaderBlock title={lang === 'ru' ? 'НОМЕРА' : 'ROOMS'} after={'#f3b246'} width={'20%'} />
      <div ref={ref} className={style.homeRoomsContainer}>
        <Slider {...settings}>
          {arrRooms && arrRooms.map(room => <HomeRoom key={room.id} room={room} />)}
        </Slider>
      </div>
    </div>
  )
}
