import React from 'react'

import style from './Booking.module.scss'
import bookingLogo from './img/booking-logo.svg'

import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const Booking = () => {
  const lang = useAppSelector(language)
  const mainPage = useAppSelector(state => state.admin.mainPage)

  return (
    <div className={style.booking}>
      <div className={style.bookingLine}></div>
      <img src={bookingLogo} alt="" />
      <span>{lang === 'ru' ? mainPage.booking_rating : mainPage.booking_rating_en}</span>
      <p>{lang === 'ru' ? mainPage.booking_title : mainPage.booking_title_en}</p>
    </div>
  )
}
