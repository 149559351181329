import React from 'react'

import style from './FAQBlock.module.scss'
import { FAQItem } from './FAQItem/FAQItem'

import { FAQType } from 'api/types'

type FAQBlockType = {
  arr: FAQType[]
}

export const FAQBlock: React.FC<FAQBlockType> = ({ arr }) => {
  return (
    <div className={style.FAQBlock}>
      {arr.map((f, index) => (
        <FAQItem key={index} FAQ={f} />
      ))}
    </div>
  )
}
