import React from 'react'

import Paper from '@mui/material/Paper/Paper'

import style from './WhatIsNearCard.module.scss'

import { PlaceType } from 'components/c2-Body/b1-Home/h6-WhatIsNear/WhatIsNear'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

type RoomCT = { place: PlaceType }

export const WhatIsNearCard: React.FC<RoomCT> = ({ place }) => {
  const lang = useAppSelector(language)

  return (
    <Paper
      style={{
        width: '90%',
        margin: '30px auto',
        height: '500px',
      }}
      square
      elevation={9}
    >
      <div className={style.nearContainer}>
        <div className={style.near}>
          <div className={style.nearImg}>
            <img src={place.img} alt="" />
          </div>
          <div className={style.nearTitle}>{lang === 'ru' ? place.title : place.title_en}</div>
          <div className={style.nearText}>{lang === 'ru' ? place.text : place.text_en}</div>
        </div>
      </div>
    </Paper>
  )
}
