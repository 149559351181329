import React from 'react'

import { useNavigate } from 'react-router-dom'

import p1 from './img/p1.svg'
import p2 from './img/p2.svg'
import p3 from './img/p3.svg'
import p4 from './img/p4.svg'
import style from './WhyUs.module.scss'

import { WhyUsCard } from 'components/c2-Body/b1-Home/h5-WhyUs/WhyUsCard/WhyUsCard'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export type WhyUsCardType = {
  img: string
  text: string
}

export const WhyUs = () => {
  const navigate = useNavigate()
  const lang = useAppSelector(language)
  const mainPage = useAppSelector(state => state.admin.mainPage)
  const arrWhyUsCard: WhyUsCardType[] = [
    { img: p1, text: lang === 'ru' ? mainPage.whyWe[0] : mainPage.whyWe_en[0] },
    { img: p2, text: lang === 'ru' ? mainPage.whyWe[1] : mainPage.whyWe_en[1] },
    { img: p3, text: lang === 'ru' ? mainPage.whyWe[2] : mainPage.whyWe_en[2] },
    { img: p4, text: lang === 'ru' ? mainPage.whyWe[3] : mainPage.whyWe_en[3] },
  ]

  return (
    <div className={style.whyUs}>
      <HeaderBlock
        title={lang === 'ru' ? 'ПОЧЕМУ МЫ?' : 'WHY US'}
        before={'#3f5a69'}
        after={'#3f5a69'}
        width={'25%'}
      />
      <div className={style.whyUsContainer}>
        {arrWhyUsCard.map(card => (
          <WhyUsCard key={card.text} card={card} />
        ))}
      </div>
      <button
        className={style.button}
        onClick={() => {
          navigate('/services')
          window.scrollTo(0, 0)
        }}
      >
        {lang === 'ru' ? 'Смотреть все услуги' : 'View all services'}
      </button>
    </div>
  )
}
