import React, { useEffect } from 'react'

import style from './NewsBlock.module.scss'

import { newsTC } from 'api/bll/adminReduser'
import { NewsBlockCard } from 'components/c2-Body/News/NewsBlock/NewsCard/NewsBlockCard'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const NewsBlock = () => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(language)

  useEffect(() => {
    dispatch(newsTC())
  }, [])

  const arrNews = useAppSelector(state => state.admin.news)

  return (
    <div className={style.roomsBlock}>
      <HeaderBlock title={lang === 'ru' ? 'НОВОСТИ' : 'NEWS'} after={'#f3b246'} width={'20%'} />
      <div className={style.newsBlock_container}>
        {arrNews && arrNews.map((news, index) => <NewsBlockCard key={news.id} news={news} />)}
      </div>
    </div>
  )
}
