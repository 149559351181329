import React, { useState } from 'react'

import Paper from '@mui/material/Paper/Paper'

import style from './HeaderContacts.module.scss'
import facebook from './img/facebook.svg'
import gmap from './img/gmap.png'
import insta from './img/insta.svg'
import mail from './img/mail.svg'
import mapIcon from './img/map.svg'
import phone from './img/phone.svg'
import shimko from './img/shimko_logo.png'
import vk from './img/vk.svg'
import ymap from './img/yamap.png'

import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

type ContactType = {
  img: string
  href: string
  text: string
  text_en: string
}

export const HeaderContacts = () => {
  const lang = useAppSelector(language)
  const contacts = useAppSelector(state => state.admin.contacts)
  const [map, setMap] = useState('yandex')

  const arrContacts: ContactType[] = [
    {
      img: phone,
      href: 'tel: +79622692028',
      text: '+7 962 269 20 28',
      text_en: '+7 962 269 20 28',
    },
    {
      img: mail,
      href: 'https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=hostel2028@gmail.com',
      text: 'hostel2028@gmail.com',
      text_en: 'hostel2028@gmail.com',
    },
    {
      img: mapIcon,
      href: 'https://www.google.de/maps/place/Hostel+2028/@54.7167609,20.4856269,17z/data=!3m1!4b1!4m8!3m7!1s0x46e33df9c169aabf:0x6b3e0d1e7112ed30!5m2!4m1!1i2!8m2!3d54.7167578!4d20.4878156',
      text: '236022 г. Калининград, ул. Гостиная 22',
      text_en: '236022 Kaliningrad, Gostinaya st. 22',
    },
    {
      img: vk,
      href: contacts.vk.link,
      text: contacts.vk.text,
      text_en: contacts.vk.text,
    },
    {
      img: facebook,
      href: contacts.facebook.link,
      text: contacts.facebook.text,
      text_en: contacts.facebook.text,
    },
    {
      img: insta,
      href: contacts.instagram.link,
      text: contacts.instagram.text,
      text_en: contacts.instagram.text,
    },
  ]

  return (
    <div className={style.contact}>
      <HeaderBlock
        title={lang === 'ru' ? 'НАШИ КОНТАКТЫ' : 'OUR CONTACTS'}
        after={'#f3b246'}
        width={'10%'}
      />
      <Paper
        style={{
          width: '90%',
          maxWidth: '700px',
          margin: '0 auto',
        }}
        square
        elevation={9}
      >
        <div className={style.container}>
          <div className={style.container__map}>
            <div className={style.container__map__buttons}>
              <div
                onClick={() => setMap('google')}
                className={style.container__map__buttons_bytton}
              >
                <img src={gmap} alt="" />
              </div>
              <div
                onClick={() => setMap('yandex')}
                className={style.container__map__buttons_bytton}
              >
                <img src={ymap} alt="" />
              </div>
            </div>
            {map !== 'google' ? (
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A24dc4109d93c3848f123afbdd086e023b714559546d02d2fa9a0745bc6405d88&amp;source=constructor"
                width="100%"
                height="100%"
                frameBorder="0"
              ></iframe>
            ) : (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2304.594281373224!2d20.485626916059456!3d54.71676087887992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e33df9c1426b09%3A0xaf6f480a1410cd1e!2z0YPQuy4g0JPQvtGB0YLQuNC90LDRjywgMjIsINCa0LDQu9C40L3QuNC90LPRgNCw0LQsINCa0LDQu9C40L3QuNC90LPRgNCw0LTRgdC60LDRjyDQvtCx0LsuLCDQoNC-0YHRgdC40Y8sIDIzNjAyMg!5e0!3m2!1sru!2sde!4v1591611994877!5m2!1sru!2sde"
                width="100%"
                height="100%"
                frameBorder="0"
                aria-hidden="false"
              ></iframe>
            )}
          </div>
          <div className={style.container__contacts}>
            {arrContacts.map((contact, index) => {
              return (
                <div key={index} className={style.container__contact}>
                  <a href={contact.href} target="blank">
                    <img src={contact.img} alt="" />
                    <span>{lang === 'ru' ? contact.text : contact.text_en}</span>
                  </a>
                </div>
              )
            })}
          </div>
          <div className={style.container__shimko}>
            <img src={shimko} alt="" />
          </div>
        </div>
      </Paper>
    </div>
  )
}
