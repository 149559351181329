import style from './Visa.module.scss'

import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const Visa = () => {
  const lang = useAppSelector(language)
  const src =
    lang === 'ru'
      ? 'https://www.bpltech.pro/invitation/kgdhostels/ru'
      : 'https://www.bpltech.pro/invitation/kgdhostels/en'

  return (
    <div>
      <HeaderBlock title={lang === 'ru' ? 'ВИЗОВАЯ ПОДДЕРЖКА' : 'VISA SUPPORT'} />
      <div className={style.containerVisa}>
        <iframe
          frameBorder="0"
          name="bplformtopiframewindow"
          width="100%"
          //scrolling="no"
          style={{ minHeight: '700px', overflow: 'hidden', height: '1900px' }}
          src={src}
          id="iFrameResizer0"
        ></iframe>
      </div>
    </div>
  )
}
