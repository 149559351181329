import React from 'react'

import Paper from '@mui/material/Paper/Paper'

import { ReviewType } from 'api/types'
import style from 'components/c2-Body/b1-Home/h7-Reviews/Review/Review.module.scss'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

type RoomCT = {
  review: ReviewType
}

export const Review: React.FC<RoomCT> = ({ review }) => {
  const lang = useAppSelector(language)

  return (
    <Paper
      style={{
        width: '100%',
        minHeight: '100%',
        margin: '5px 10px',
        alignContent: 'center',
      }}
      square
    >
      <div className={style.reviewContainer}>
        <img src={review.photo} alt="" />
        <div className={style.review}>
          <div className={style.reviewTitle}>
            {lang === 'ru' ? review.author : review.author_en}
          </div>
          <div className={style.reviewText}>{lang === 'ru' ? review.review : review.review_en}</div>
        </div>
      </div>
    </Paper>
  )
}
