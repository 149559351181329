import axios from 'axios'

import {
  AboutUsType,
  ContactsType,
  FAQType,
  HostelType,
  MainPageType,
  NewsType,
  OneNewsType,
  OneRoomType,
  ReviewType,
  RoomType,
  ServicesType,
  SharesType,
} from 'api/types'

const instance = axios.create({
  baseURL: 'https://adminhostels.ru/api/',
})

export const adminAPI = {
  getAboutUs() {
    return instance.get<AboutUsType>('aboutUs')
  },
  getFAQ() {
    return instance.get<FAQType[]>('faq')
  },
  getOurHostels() {
    return instance.get<HostelType[]>('ourHostels')
  },
  getNews() {
    return instance.get<NewsType[]>('news')
  },
  getOneNews(id: number) {
    return instance.get<OneNewsType>(`news/${id}`)
  },
  getRooms() {
    return instance.get<RoomType[]>('rooms')
  },
  getRoom(id: number) {
    return instance.get<OneRoomType>(`rooms/${id}`)
  },
  getReviews() {
    return instance.get<ReviewType[]>('reviews')
  },
  getContacts() {
    return instance.get<ContactsType>('contacts')
  },
  getServices() {
    return instance.get<ServicesType>('services')
  },
  getShares() {
    return instance.get<SharesType[]>('shares')
  },
  getMainPage() {
    return instance.get<MainPageType>('mainPage')
  },
}
