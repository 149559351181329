import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { adminAPI } from 'api/api'
import {
  AboutUsType,
  ContactsType,
  FAQType,
  HostelType,
  MainPageType,
  NewsType,
  ReviewType,
  RoomType,
  ServicesType,
  SharesType,
} from 'api/types'

//THUNKS
export const aboutUsTC = createAsyncThunk('aboutUs', async (params, { dispatch }) => {
  const res = await adminAPI.getAboutUs()

  dispatch(setAboutUs(res.data))
})
export const FAQTC = createAsyncThunk('FAQ', async (params, { dispatch }) => {
  const res = await adminAPI.getFAQ()

  dispatch(setFAQ(res.data))
})
export const ourHostelsTC = createAsyncThunk('ourHostels', async (params, { dispatch }) => {
  const res = await adminAPI.getOurHostels()

  dispatch(setOurHostels(res.data))
})
export const newsTC = createAsyncThunk('news', async (params, { dispatch }) => {
  const res = await adminAPI.getNews()

  dispatch(setNews(res.data))
})
export const roomsTC = createAsyncThunk('rooms', async (params, { dispatch }) => {
  const res = await adminAPI.getRooms()

  dispatch(setRooms(res.data))
})
export const reviewsTC = createAsyncThunk('reviews', async (params, { dispatch }) => {
  const res = await adminAPI.getReviews()

  dispatch(setReviews(res.data))
})
export const contactsTC = createAsyncThunk('contacts', async (params, { dispatch }) => {
  const res = await adminAPI.getContacts()

  dispatch(setContacts(res.data))
})
export const servicesTC = createAsyncThunk('services', async (params, { dispatch }) => {
  const res = await adminAPI.getServices()

  dispatch(setServices(res.data))
})
export const sharesTC = createAsyncThunk('shares', async (params, { dispatch }) => {
  const res = await adminAPI.getShares()

  dispatch(setShares(res.data))
})
export const mainPageTC = createAsyncThunk('mainPage', async (params, { dispatch }) => {
  const res = await adminAPI.getMainPage()

  dispatch(setMainPage(res.data))
})

const slice = createSlice({
  name: 'admin',
  initialState: {
    mainPage: {
      h1: '',
      sub_h1: '',
      book_link: '',
      main_page_video: '',
      whyWe: [''],
      booking_title: '',
      booking_rating: '',
      h1_en: '',
      sub_h1_en: '',
      book_link_en: '',
      whyWe_en: [''],
      booking_title_en: '',
      booking_rating_en: '',
    },
    aboutUs: {
      content: '',
      content_en: '',
      photos: [''],
      gallery: [''],
    },
    FAQ: [
      {
        title: '',
        description: '',
        title_en: '',
        description_en: '',
      },
    ],
    ourHostels: [
      {
        title: '',
        title_en: '',
        link: '',
        content1: '',
        content2: '',
        content3: '',
        content1_en: '',
        content2_en: '',
        content3_en: '',
        photo: '',
      },
    ],
    reviews: [
      {
        photo: '',
        author: '',
        author_en: '',
        review: '',
        review_en: '',
      },
    ],
    news: [
      {
        id: 1,
        short_description: '',
        preview: '',
        date: '',
        photo: '',
        short_description_en: '',
        preview_en: '',
      },
    ],
    rooms: [
      {
        id: 1,
        title: '',
        title_en: '',
        preview: '',
        preview_en: '',
        photo: '',
      },
    ],
    shares: [
      {
        title: '',
        share: '',
        photo: '',
        title_en: '',
        share_en: '',
      },
    ],
    services: {
      free: [
        {
          file: '',
          text: '',
        },
      ],
      paid: [
        {
          file: '',
          text: '',
        },
      ],
      free_en: [
        {
          file: '',
          text: '',
        },
      ],
      paid_en: [
        {
          file: '',
          text: '',
        },
      ],
    },
    contacts: {
      facebook: {
        text: '',
        link: '',
      },
      instagram: {
        text: '',
        link: '',
      },
      vk: {
        text: '',
        link: '',
      },
      whats_app: {
        text: '',
        link: '',
      },
    },
  },
  reducers: {
    setAboutUs(state, action: PayloadAction<AboutUsType>) {
      state.aboutUs = action.payload
    },
    setFAQ(state, action: PayloadAction<FAQType[]>) {
      state.FAQ = action.payload
    },
    setOurHostels(state, action: PayloadAction<HostelType[]>) {
      state.ourHostels = action.payload
    },
    setNews(state, action: PayloadAction<NewsType[]>) {
      state.news = action.payload
    },
    setReviews(state, action: PayloadAction<ReviewType[]>) {
      state.reviews = action.payload
    },
    setRooms(state, action: PayloadAction<RoomType[]>) {
      state.rooms = action.payload
    },
    setContacts(state, action: PayloadAction<ContactsType>) {
      state.contacts = action.payload
    },
    setServices(state, action: PayloadAction<ServicesType>) {
      state.services = action.payload
    },
    setShares(state, action: PayloadAction<SharesType[]>) {
      state.shares = action.payload
    },
    setMainPage(state, action: PayloadAction<MainPageType>) {
      state.mainPage = action.payload
    },
  },
})

export const adminReducer = slice.reducer

export const {
  setAboutUs,
  setFAQ,
  setOurHostels,
  setReviews,
  setNews,
  setRooms,
  setContacts,
  setServices,
  setShares,
  setMainPage,
} = slice.actions
