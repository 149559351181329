import React from 'react'

import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import style from './NewsCard.module.scss'

import { NewsType } from 'api/types'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

type NewsCardType = {
  news: NewsType
}

export const NewsCard: React.FC<NewsCardType> = ({ news }) => {
  const navigate = useNavigate()
  const goToNews = (id: string) => {
    window.scrollTo(0, 0)
    navigate(`/news/${id}`)
  }
  const lang = useAppSelector(language)

  return (
    <Paper
      style={{ width: '300px', minHeight: '420px', margin: '10px', cursor: 'pointer' }}
      square
      elevation={9}
      onClick={() => goToNews(String(news.id))}
    >
      <div className={style.newsContainer}>
        <div className={style.news}>
          <div className={style.newsImg}>
            <img src={news.photo} alt="" />
          </div>
          <div className={style.newsTitle}>
            {lang === 'ru' ? news.short_description : news.short_description_en}
          </div>
          <div className={style.newsText}>{lang === 'ru' ? news.preview : news.preview_en}</div>
          <span className={style.newsData}>{news.date}</span>
        </div>
      </div>
    </Paper>
  )
}
