import React, { useEffect } from 'react'

import { AboutUsHeader } from './a1-AboutUsHeader/AboutUsHeader'
import { AdditionalServices } from './a2-AdditionalServices/AdditionalServices'
import { Gallery } from './a3-Gallery/Gallery'

import { aboutUsTC } from 'api/bll/adminReduser'
import style from 'components/c2-Body/b2-AboutUs/AboutUs.module.scss'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const AboutUs = () => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(language)

  useEffect(() => {
    dispatch(aboutUsTC())
  }, [])

  return (
    <div className={style.aboutUs}>
      <HeaderBlock title={lang === 'ru' ? 'О НАС' : 'ABOUT US'} after={'#f3b246'} width={'15%'} />
      <AboutUsHeader />
      <AdditionalServices />
      <Gallery />
    </div>
  )
}
