import React from 'react'

import { FAQType } from 'api/types'
import { FAQBlock } from 'components/c2-Body/b5-FAQ/FAQBlock/FAQBlock'
import style from 'components/c2-Body/b7-Contact/HouToGet/HouToGet.module.scss'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const arrHouToGet: FAQType[] = [
  {
    title: 'Из аэропорта «Храброво»',
    description:
      'С 08:30 – 21:30, от Аэропорта ходит автобус 244э каждые 30 минут до южного вокзала г.Калининграда.',
    title_en: 'From the «Khrabrovo» airport',
    description_en:
      'From 08:30 - 21:30, from the Airport goes bus 244e every 30 minutes to the southern station of Kaliningrad.',
  },
  {
    title: 'От Южного вокзала',
    description:
      'От остановки «проспект Калинина» (Южный вокзал) автобусы 18, 63, 5, 8, 36 до остановки «Зоопарк».\n' +
      'Далее по ул. Гостиная 300м.',
    title_en: 'From South Station',
    description_en:
      'From the stop "Kalinin Avenue" (South Station) buses 18, 63, 5, 8, 36 to the stop "Zoo".\n' +
      'Further on Gostinaya St. 300m.',
  },
]
export const HouToGet = () => {
  const lang = useAppSelector(language)

  return (
    <div className={style.houToGet}>
      <HeaderBlock
        title={lang === 'ru' ? 'КАК ДОБРАТЬСЯ' : 'HOW TO GET THERE'}
        before={'#3f5a69'}
        after={'#3f5a69'}
        width={'15%'}
      />
      <FAQBlock arr={arrHouToGet} />
    </div>
  )
}
