import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import style from './HomeFAQ.module.scss'

import { FAQTC } from 'api/bll/adminReduser'
import { FAQBlock } from 'components/c2-Body/b5-FAQ/FAQBlock/FAQBlock'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const HomeFAQ = () => {
  const lang = useAppSelector(language)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(FAQTC())
  }, [])

  const arrFAQ = useAppSelector(state => state.admin.FAQ)

  return (
    <div className={style.homeFAQ}>
      <HeaderBlock
        title={lang === 'ru' ? 'FAQ' : 'FAQ'}
        before={'#3f5a69'}
        after={'#3f5a69'}
        width={'25%'}
      />
      <FAQBlock arr={arrFAQ} />
      <button
        className={style.button}
        onClick={() => {
          navigate('/FAQ')
          window.scrollTo(0, 0)
        }}
      >
        {lang === 'ru' ? 'Показать больше' : 'Show more'}
      </button>
    </div>
  )
}
