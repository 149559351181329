import React, { useEffect } from 'react'

import Slider from 'react-slick'

import { useElementWidth } from '../../../../hooks/useElementWidth'

import style from './AdditionalServices.module.scss'

import { servicesTC } from 'api/bll/adminReduser'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { SampleNextArrow, SamplePrevArrow } from 'components/common/Slider/SliderArrows'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const AdditionalServices = () => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(language)

  useEffect(() => {
    dispatch(servicesTC())
  }, [])
  const arrPaidServices = useAppSelector(state => state.admin.services.paid)
  const arrPaidServicesEn = useAppSelector(state => state.admin.services.paid_en)
  const windowInnerWidth = window.innerWidth
  const [ref, width] = useElementWidth()
  const slidesShowFn = () => {
    let slidesShow = 5

    if (windowInnerWidth <= 1600) {
      slidesShow = 4
    }

    if (windowInnerWidth <= 1280) {
      slidesShow = 3
    }
    if (windowInnerWidth <= 768) {
      slidesShow = 2
    }
    if (windowInnerWidth <= 576) {
      slidesShow = 1
    }

    return slidesShow
  }
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesShowFn(),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <div className={style.services}>
      <HeaderBlock
        title={lang === 'ru' ? 'ДОПОЛНИТЕЛЬНЫЕ УСЛУГИ' : 'SERVICES'}
        before={'#3f5a69'}
        after={'#3f5a69'}
        width={'15%'}
      />
      <div ref={ref} className={style.servicesContainer}>
        <Slider {...settings}>
          {(lang === 'ru' ? arrPaidServices : arrPaidServicesEn).map((service, index) => {
            return (
              <div key={index}>
                <div className={style.slide}>
                  <div className={style.slideImg}>
                    <img src={service.file} alt="" />
                  </div>
                  <span className={style.slideText}>{service.text}</span>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
