import React from 'react'

import style from './WhyUsCard.module.scss'

import { WhyUsCardType } from 'components/c2-Body/b1-Home/h5-WhyUs/WhyUs'

type WhyUsCardCT = {
  card: WhyUsCardType
}

export const WhyUsCard: React.FC<WhyUsCardCT> = ({ card }) => {
  return (
    <div className={style.whyUsCard}>
      <div className={style.picture}>
        <img src={card.img} alt="" />
      </div>
      <p>{card.text}</p>
    </div>
  )
}
