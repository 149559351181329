import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { adminAPI } from 'api/api'
import { OneNewsType } from 'api/types'

//THUNKS
export const oneNewsTC = createAsyncThunk('oneNews', async (id: number, { dispatch }) => {
  const res = await adminAPI.getOneNews(id)

  dispatch(setOneNews(res.data))
})

const slice = createSlice({
  name: 'news',
  initialState: {
    oneNews: {
      id: 1,
      short_description: '',
      content: '',
      photo: '',
      short_description_en: '',
      content_en: '',
    },
  },
  reducers: {
    setOneNews(state, action: PayloadAction<OneNewsType>) {
      state.oneNews = action.payload
    },
  },
})

export const newsReducer = slice.reducer

export const { setOneNews } = slice.actions
