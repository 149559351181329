import React from 'react'

import style from './FAQ.module.scss'
import { FAQBlock } from './FAQBlock/FAQBlock'

import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppSelector } from 'hooks/hooks'

export const FAQ = () => {
  const arrFAQ = useAppSelector(state => state.admin.FAQ)

  return (
    <div className={style.FAQ}>
      <HeaderBlock title={'FAQ'} after={'#f3b246'} width={'20%'} />
      <FAQBlock arr={arrFAQ} />
    </div>
  )
}
