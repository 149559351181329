import React from 'react'

import style from './Documents.module.scss'

import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const Documents = () => {
  const lang = useAppSelector(language)

  return (
    <div className={style.contacts}>
      <HeaderBlock
        title={lang === 'ru' ? 'ДОКУМЕНТЫ' : 'DOCUMENTS'}
        before={'#f3b246'}
        width={'10%'}
      />
      <div className={style.text}>
        {lang === 'ru'
          ? 'Вы можете ознакомиться с договором публичной оферты перейдя по'
          : 'You can familiarize yourself with the public offer agreement by clicking on the'}{' '}
        <a
          href={lang === 'ru' ? './documents/dog_ru.docx' : './documents/dog_en.docx'}
          download=""
          target="_blank"
          rel="noreferrer"
        >
          {lang === 'ru' ? 'ссылке' : 'link'}
        </a>
        .
      </div>
    </div>
  )
}
