import React, { useEffect } from 'react'

import { NavLink } from 'react-router-dom'

import f from './img/f.svg'
import i from './img/i.svg'
import vk from './img/vk.svg'
import whats from './img/whats.svg'

import { contactsTC } from 'api/bll/adminReduser'
import { scrollUp } from 'components/c1-Header/h1-Menu/HeaderMenu'
import logo from 'components/c1-Header/h1-Menu/img/logo.svg'
import style from 'components/c3-Footer/Footer.module.scss'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const Footer = () => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(language)
  const mainPage = useAppSelector(state => state.admin.mainPage)

  useEffect(() => {
    dispatch(contactsTC())
  }, [])
  const contacts = useAppSelector(state => state.admin.contacts)

  const scrollTo = (block: string) => {
    const timeout = 100

    setTimeout(() => {
      const divElement = document.getElementById(block)

      if (divElement) {
        divElement.scrollIntoView({ behavior: 'smooth' })
      }
    }, timeout)
  }

  return (
    <div className={style.background}>
      <div className={style.footer}>
        <div className={style.footerBlocks}>
          <div className={style.footerBlock1}>
            <div className={style.logo}>
              <img src={logo} alt="" />
            </div>
            <div className={style.text}>
              {lang === 'ru'
                ? 'Уютный хостел в Калининграде предлагает своим гостям комфортный и экономный вариант\n' +
                  '              проживания в центре города.'
                : 'Cozy hostel in Kaliningrad offers its guests a comfortable and economical option of accommodation in the city center.'}
            </div>
          </div>
          <div className={style.footerBlock2}>
            <div className={style.menu}>
              <NavLink onClick={scrollUp} to="/home">
                {lang === 'ru' ? 'Главная' : 'Home'}
              </NavLink>
              <NavLink onClick={scrollUp} to="/about_us">
                {lang === 'ru' ? 'О нас' : 'About Us'}
              </NavLink>
              <NavLink onClick={scrollUp} to="/rooms">
                {lang === 'ru' ? 'Номера' : 'Rooms'}
              </NavLink>
              <NavLink onClick={scrollUp} to="/services">
                {lang === 'ru' ? 'Услуги' : 'Services'}
              </NavLink>
              <NavLink onClick={scrollUp} to="/FAQ">
                {lang === 'ru' ? 'FAQ' : 'FAQ'}
              </NavLink>
              <NavLink onClick={scrollUp} to="/contact">
                {lang === 'ru' ? 'Контакты' : 'Contact'}
              </NavLink>
              <NavLink onClick={() => scrollTo('shares')} to="/home">
                {lang === 'ru' ? 'Акции' : 'Promotions'}
              </NavLink>
              <NavLink onClick={() => scrollTo('reviews')} to="/home">
                {lang === 'ru' ? 'Отзывы' : 'Reviews'}
              </NavLink>
              <NavLink onClick={scrollUp} to="/visa">
                {lang === 'ru' ? 'Визовая поддержка' : 'Visa Support'}
              </NavLink>
              <NavLink onClick={scrollUp} to="/news">
                {lang === 'ru' ? 'Новости' : 'News'}
              </NavLink>
            </div>
            <div className={style.links}>
              <a
                href="/documents/Договор%20Оферты%20Хостела%202028.pdf"
                target={'_blank'}
                rel="noreferrer"
              >
                {lang === 'ru' ? 'Оферта' : 'Offer'}
              </a>
              <a
                href="/documents/Правила%20проживания%20в%20Хостеле%202028.pdf"
                target={'_blank'}
                rel="noreferrer"
              >
                {lang === 'ru' ? 'Правила проживания' : 'Residence Rules'}
              </a>
              <a
                href="/documents/Уголок%20потребителя%202028.pdf"
                target={'_blank'}
                rel="noreferrer"
              >
                {lang === 'ru' ? 'Уголок потребителя' : 'Consumer corner'}
              </a>
            </div>
          </div>
          <div className={style.footerBlock3}>
            <form
              action={lang === 'ru' ? mainPage.book_link : mainPage.book_link_en}
              target="_blank"
            >
              <button>{lang === 'ru' ? 'Забронировать номер' : 'Book now'}</button>
            </form>
            <div>
              <a href={'tel: +79622692028'}>+7 (962) 269-20-28</a>
              <p>
                {lang === 'ru'
                  ? 'г. Калининград, ул. Гостиная 22'
                  : 'Kaliningrad, Gostinaya st. 22'}
              </p>
            </div>
            <div className={style.socials}>
              <a href={contacts.facebook.link} target={'_blank'} rel="noreferrer">
                <img src={f} alt="" />
              </a>
              <a href={contacts.instagram.link} target={'_blank'} rel="noreferrer">
                <img src={i} alt="" />
              </a>
              <a href={contacts.vk.link} target={'_blank'} rel="noreferrer">
                <img src={vk} alt="" />
              </a>
              <a href={contacts.whats_app.link} target={'_blank'} rel="noreferrer">
                <img src={whats} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className={style.footerInfo}>
          <div className={style.footerInfoLinks}>
            <a
              href="/documents/Политика%20конфиденциальности%20Хостел%202028.pdf"
              target={'_blank'}
              rel="noreferrer"
            >
              {lang === 'ru' ? 'Политика конфиденциальности' : 'Privacy Policy'}
            </a>
            <a
              href="/documents/Пользовательское%20соглашение%20Х%202028.pdf"
              target={'_blank'}
              rel="noreferrer"
            >
              {lang === 'ru' ? 'Пользовательское соглашение' : 'Terms of use'}
            </a>
          </div>
          <div className={style.hostel2028}>© 2021 “Hostel 2028”</div>
        </div>
      </div>
    </div>
  )
}
