import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import style from './News.module.scss'
import { NewsCard } from './NewsCard/NewsCard'

import { newsTC } from 'api/bll/adminReduser'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const News = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const lang = useAppSelector(language)

  useEffect(() => {
    dispatch(newsTC())
  }, [])
  const arrNews = useAppSelector(state => state.admin.news)

  return (
    <div className={style.news}>
      <HeaderBlock title={lang === 'ru' ? 'НОВОСТИ' : 'NEWS'} before={'#f3b246'} width={'25%'} />
      <div className={style.newsCardsContainer}>
        {arrNews && arrNews.map((news, index) => <NewsCard key={news.id} news={news} />)}
      </div>
      <button
        className={style.button}
        onClick={() => {
          navigate('/news')
          window.scrollTo(0, 0)
        }}
      >
        {lang === 'ru' ? 'Показать больше' : 'Show more'}
      </button>
    </div>
  )
}
