import React from 'react'

import arrowLBlue from 'components/c2-Body/img/arrows/BLUE/ArrowLBlue.svg'
import arrowRBlue from 'components/c2-Body/img/arrows/BLUE/ArrowRBlue.svg'
import arrowL from 'components/c2-Body/img/arrows/FFF/ArrowL.svg'
import arrowR from 'components/c2-Body/img/arrows/FFF/ArrowR.svg'

export const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: '32px',
        height: '50px',
        content: `url(${arrowRBlue})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        right: '-40px',
      }}
      onClick={onClick}
    />
  )
}

export const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: '32px',
        height: '50px',
        content: `url(${arrowLBlue})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        zIndex: 10,
        left: '-40px',
      }}
      onClick={onClick}
    />
  )
}

export const SampleNextArrowInside = (props: any) => {
  const { className, style, onClick } = props

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: '32px',
        height: '50px',
        content: `url(${arrowR})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        right: '30px',
      }}
      onClick={onClick}
    />
  )
}

export const SamplePrevArrowInside = (props: any) => {
  const { className, style, onClick } = props

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: '32px',
        height: '50px',
        content: `url(${arrowL})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        zIndex: 10,
        left: '30px',
      }}
      onClick={onClick}
    />
  )
}
