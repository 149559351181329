import React from 'react'

import style from './InfoBlock.module.scss'

import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'

type InfoBlockType = {
  title: string
  text: string
}

export const InfoBlock: React.FC<InfoBlockType> = ({ title, text }) => {
  return (
    <div className={style.infoBlock}>
      <HeaderBlock title={title} before={'#3f5a69'} after={'#3f5a69'} width={'10%'} />
      <div className={style.infoBlock__text}>{text}</div>
    </div>
  )
}
