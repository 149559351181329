import React, { useEffect } from 'react'

import Paper from '@mui/material/Paper/Paper'
import { useParams } from 'react-router-dom'

import style from './NewsPage.module.scss'

import { oneNewsTC } from 'api/bll/newsReduser'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const NewsPage = () => {
  let { id } = useParams()
  const lang = useAppSelector(language)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(oneNewsTC(Number(id)))
  }, [id])
  const news = useAppSelector(state => state.oneNews.oneNews)
  let content = { __html: lang === 'ru' ? news.content : news.content_en }

  return (
    <div>
      <HeaderBlock title={lang === 'ru' ? news.short_description : news.short_description_en} />
      <Paper
        style={{
          width: '90%',
          maxWidth: '800px',
          margin: '40px auto 60px',
        }}
        square
        elevation={9}
      >
        <div className={style.newsContainer}>
          <div className={style.newsImg}>
            <img src={news.photo} alt="" />
          </div>
          <div dangerouslySetInnerHTML={content} className={style.context} />
        </div>
      </Paper>
    </div>
  )
}
