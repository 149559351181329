import React, { useState } from 'react'

import { Menu, MenuItem } from '@mui/material'
import { NavLink } from 'react-router-dom'

import menu from './img/menu.svg'

import style from 'components/c1-Header/h1-Menu/HeaderMenu.module.scss'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const scrollUp = (): void => {
  window.scrollTo(0, 0)
}

export type ActionType = {
  [key: string]: boolean
}

export const HeaderMenu = () => {
  const lang = useAppSelector(language)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const handleCloseAndScrollUp = (): void => {
    setAnchorEl(null)
    window.scrollTo(0, 0)
  }

  const setAction = ({ isActive }: ActionType) => (isActive ? style.active : style.burgerLink)

  return (
    <div className={style.container}>
      <div className={style.burger}>
        <button type="button" className={style.buttonMenu} onClick={handleClick}>
          <img src={menu} alt="" />
        </button>
        <Menu
          className={style.burgerLinks}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            className={`${style.burgerLink} ${style.active}`}
            onClick={handleCloseAndScrollUp}
          >
            <NavLink onClick={scrollUp} to="/home" className={setAction}>
              {lang === 'ru' ? 'Главная' : 'Home'}
            </NavLink>
          </MenuItem>
          <MenuItem
            className={`${style.burgerLink} ${style.active}`}
            onClick={handleCloseAndScrollUp}
          >
            <NavLink to="/about_us" className={setAction}>
              {lang === 'ru' ? 'О нас' : 'About Us'}
            </NavLink>
          </MenuItem>
          <MenuItem
            className={`${style.burgerLink} ${style.active}`}
            onClick={handleCloseAndScrollUp}
          >
            <NavLink onClick={scrollUp} to="/rooms" className={setAction}>
              {lang === 'ru' ? 'Номера' : 'Rooms'}
            </NavLink>
          </MenuItem>
          <MenuItem
            className={`${style.burgerLink} ${style.active}`}
            onClick={handleCloseAndScrollUp}
          >
            <NavLink onClick={scrollUp} to="/services" className={setAction}>
              {lang === 'ru' ? 'Услуги' : 'Services'}
            </NavLink>
          </MenuItem>
          <MenuItem
            className={`${style.burgerLink} ${style.active}`}
            onClick={handleCloseAndScrollUp}
          >
            <NavLink onClick={scrollUp} to="/FAQ" className={setAction}>
              {lang === 'ru' ? 'FAQ' : 'FAQ'}
            </NavLink>
          </MenuItem>
          <MenuItem
            className={`${style.burgerLink} ${style.active}`}
            onClick={handleCloseAndScrollUp}
          >
            <NavLink onClick={scrollUp} to="/contact" className={setAction}>
              {lang === 'ru' ? 'Контакты' : 'Contact'}
            </NavLink>
          </MenuItem>
        </Menu>
      </div>
      <div className={`${style.menu} ${style.active}`}>
        <NavLink onClick={scrollUp} to="/home" className={setAction}>
          {lang === 'ru' ? 'Главная' : 'Home'}
        </NavLink>
        <NavLink onClick={scrollUp} to="/about_us" className={setAction}>
          {lang === 'ru' ? 'О нас' : 'About Us'}
        </NavLink>
        <NavLink onClick={scrollUp} to="/rooms" className={setAction}>
          {lang === 'ru' ? 'Номера' : 'Rooms'}
        </NavLink>
        <NavLink onClick={scrollUp} to="/services" className={setAction}>
          {lang === 'ru' ? 'Услуги' : 'Services'}
        </NavLink>
        <NavLink onClick={scrollUp} to="/FAQ" className={setAction}>
          {lang === 'ru' ? 'FAQ' : 'FAQ'}
        </NavLink>
        <NavLink onClick={scrollUp} to="/contact" className={setAction}>
          {lang === 'ru' ? 'Контакты' : 'Contact'}
        </NavLink>
      </div>
    </div>
  )
}
