import React from 'react'

import style from './Language.module.scss'

import { setLanguage } from 'api/bll/languageReduser'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const Language = () => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(language)
  const activeRu = lang === 'ru' ? `${style.active}` : ''
  const activeEn = lang === 'en' ? `${style.active}` : ''

  return (
    <div className={style.language}>
      <div className={activeRu} onClick={() => dispatch(setLanguage('ru'))}>
        РУС
      </div>
      {`  |  `}
      <div className={activeEn} onClick={() => dispatch(setLanguage('en'))}>
        ENG
      </div>
    </div>
  )
}
