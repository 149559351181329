import React from 'react'

import Paper from '@mui/material/Paper/Paper'
import { useNavigate } from 'react-router-dom'

import style from './HomeRoom.module.scss'

import { RoomType } from 'api/types'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export type RoomCT = {
  room: RoomType
}

export const HomeRoom: React.FC<RoomCT> = ({ room }) => {
  const lang = useAppSelector(language)
  const navigate = useNavigate()
  const goToRoom = (id: string) => {
    window.scrollTo(0, 0)
    navigate(`/rooms/${id}`)
  }

  return (
    <Paper
      style={{
        width: '90%',
        margin: '20px auto',
        height: '550px',
      }}
      square
      elevation={9}
    >
      <div className={style.roomContainer}>
        <div className={style.room}>
          <div className={style.roomImg}>
            <img src={room.photo} alt="" />
          </div>
          <div className={style.roomTitle}>{lang === 'ru' ? room.title : room.title_en}</div>
          <div className={style.roomText}>{lang === 'ru' ? room.preview : room.preview_en}</div>
        </div>
        <button className={style.button} onClick={() => goToRoom(String(room.id))}>
          {lang === 'ru' ? 'Подробнее' : 'Learn more'}
        </button>
      </div>
    </Paper>
  )
}
