import React from 'react'

import paid1 from './img/paid-1.svg'
import paid10 from './img/paid-10.svg'
import paid11 from './img/paid-11.svg'
import paid12 from './img/paid-12.svg'
import paid2 from './img/paid-2.svg'
import paid3 from './img/paid-3.svg'
import paid4 from './img/paid-4.svg'
import paid5 from './img/paid-5.svg'
import paid6 from './img/paid-6.svg'
import paid7 from './img/paid-7.svg'
import paid8 from './img/paid-8.svg'
import paid9 from './img/paid-9.svg'
import style from './PaidServices.module.scss'

import { ServicesBlock } from 'components/c2-Body/b4-Services/ServicesBlock/ServicesBlock'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const arrPaidServices = [
  { img: paid1, text: 'Оригинальные сувениры' },
  { img: paid2, text: 'Визовая поддержка' },
  { img: paid3, text: 'Прокат велосипедов и самокатов' },
  { img: paid4, text: 'Организация экскурсий' },
  { img: paid5, text: 'Дополнительная смена белья и полотенец' },
  { img: paid6, text: 'Услуги прачечной' },
  { img: paid7, text: 'Халаты' },
  { img: paid8, text: 'Трансфер' },
  { img: paid9, text: 'Завтраки' },
  { img: paid10, text: 'Тапочки' },
  { img: paid11, text: 'Косметические наборы' },
  { img: paid12, text: 'Гигиенические наборы' },
]
export const PaidServices = () => {
  const lang = useAppSelector(language)
  const arrPaidServices = useAppSelector(state => state.admin.services.paid)
  const arrPaidServicesEn = useAppSelector(state => state.admin.services.paid_en)

  return (
    <div className={style.servicesBlock}>
      <HeaderBlock
        title={lang === 'ru' ? 'ПЛАТНЫЕ УСЛУГИ' : 'PAID SERVICES'}
        before={'#f3b246'}
        width={'20%'}
      />
      <ServicesBlock arr={lang === 'ru' ? arrPaidServices : arrPaidServicesEn} />
    </div>
  )
}
