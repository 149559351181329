import React from 'react'

import style from './FreeServices.module.scss'

import left1 from 'components/c2-Body/b2-AboutUs/img/left1.jpeg'
import { ServicesBlock } from 'components/c2-Body/b4-Services/ServicesBlock/ServicesBlock'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const FreeServices = () => {
  const lang = useAppSelector(language)
  const arrFreeServices = useAppSelector(state => state.admin.services.free)
  const arrFreeServicesEn = useAppSelector(state => state.admin.services.free_en)

  return (
    <div className={style.servicesBlock}>
      <div className={style.imgBlock_left}>
        <img src={left1} alt="" />
      </div>
      <HeaderBlock
        title={lang === 'ru' ? 'БЕСПЛАТНЫЕ УСЛУГИ' : 'FREE SERVICES'}
        after={'#f3b246'}
        width={'20%'}
      />
      <ServicesBlock arr={lang === 'ru' ? arrFreeServices : arrFreeServicesEn} />
    </div>
  )
}
