import React, { useEffect } from 'react'

import Paper from '@mui/material/Paper/Paper'
import { useNavigate } from 'react-router-dom'

import style from './RoomCard.module.scss'

import { mainPageTC } from 'api/bll/adminReduser'
import { RoomCT } from 'components/c2-Body/b1-Home/h3-HomeRooms/HomeRoom/HomeRoom'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const RoomCard: React.FC<RoomCT> = ({ room }) => {
  const lang = useAppSelector(language)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(mainPageTC())
  }, [])
  const mainPage = useAppSelector(state => state.admin.mainPage)

  const goToRoom = (id: string) => {
    window.scrollTo(0, 0)
    navigate(`/rooms/${id}`)
  }

  return (
    <div className={style.room}>
      <Paper
        style={{
          width: '100%',
          height: '100%',
        }}
        square
        elevation={9}
      >
        <div className={style.roomContainer}>
          <div className={style.roomImg}>
            <img src={room.photo} alt="" />
          </div>
          <div className={style.roomTitle}>{lang === 'ru' ? room.title : room.title_en}</div>
          <div className={style.roomText}>{lang === 'ru' ? room.preview : room.preview_en}</div>
          <div className={style.buttons}>
            <button className={style.buttons_detailed} onClick={() => goToRoom(String(room.id))}>
              {lang === 'ru' ? 'Подробнее' : 'Learn more'}
            </button>
            <form
              action={lang === 'ru' ? mainPage.book_link : mainPage.book_link_en}
              target="_blank"
              className={style.buttons_reserve}
            >
              <button>{lang === 'ru' ? 'Забронировать' : 'Book now'}</button>
            </form>
          </div>
        </div>
      </Paper>
    </div>
  )
}
