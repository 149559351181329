import React from 'react'

import { HomeFAQ } from '../b1-Home/h8-HomeFAQ/HomeFAQ'

import style from './Rooms.module.scss'

import { RoomsBlock } from 'components/c2-Body/b3-Rooms/RoomsBlock/RoomsBlock'
import { InfoBlock } from 'components/common/InfoBlock/InfoBlock'
import { useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

const text =
  'У нас вы найдете все необходимое для хорошего отдыха. Удобная лаунж-зона, красивая и функциональная кухня, уютные номера встретят вас, если вы решите стать нашим гостем. В нашей команде заняты молодые и веселые ребята, которые всегда встретят вас с улыбкой и угостят кружкой кофе или чая. Мы уже подумали о вас, поэтому вы будете чувствовать себя как дома. Наши двери открыты для вас всегда и мы готовы ответить на любые ваши вопросы 24/7.'
const text_en =
  'Hostel2028 is the most comfortable and modern hostel in Kaliningrad. We are ideal for travellers looking for accommodation in the city centre. We are stylish, CLEAN and secure for you.\n' +
  '\n' +
  'We have comfortable lounge, full equipped kitchen, cozy rooms. In our team are the best administrators. We have already thought of you to feel like at home.'

export const Rooms = () => {
  const lang = useAppSelector(language)

  return (
    <div className={style.rooms}>
      <RoomsBlock />
      <InfoBlock
        title={
          lang === 'ru'
            ? 'КАЧЕСТВО УСЛУГ НАСТОЯЩЕГО ОТЕЛЯ'
            : 'CENTER LOCATION AND EXCELLENT SERVICE'
        }
        text={lang === 'ru' ? text : text_en}
      />
      <HomeFAQ />
    </div>
  )
}
