import React, { useEffect } from 'react'

import { FreeServices } from './FreeServices/FreeServices'
import style from './Services.module.scss'

import { aboutUsTC, servicesTC } from 'api/bll/adminReduser'
import { PaidServices } from 'components/c2-Body/b4-Services/PaidServices/PaidServices'
import { InfoBlock } from 'components/common/InfoBlock/InfoBlock'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

const text =
  'У нас вы найдете все необходимое для хорошего отдыха. Удобная лаунж-зона, красивая и функциональная кухня, уютные номера встретят вас, если вы решите стать нашим гостем. В нашей команде заняты молодые и веселые ребята, которые всегда встретят вас с улыбкой и угостят кружкой кофе или чая. Мы уже подумали о вас, поэтому вы будете чувствовать себя как дома. Наши двери открыты для вас всегда и мы готовы ответить на любые ваши вопросы 24/7.'
const text_en =
  'Hostel2028 is the most comfortable and modern hostel in Kaliningrad. We are ideal for travellers looking for accommodation in the city centre. We are stylish, CLEAN and secure for you. We have comfortable lounge, full equipped kitchen, cozy rooms. In our team are the best administrators. We have already thought of you to feel like at home.'

export const Services = () => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(language)

  useEffect(() => {
    dispatch(servicesTC())
  }, [])

  return (
    <div className={style.services}>
      <FreeServices />
      <InfoBlock
        title={lang === 'ru' ? 'БОЛЬШЕ, ЧЕМ ДОМ – ХОСТЕЛ 2028' : 'MORE THAN A HOME – HOSTEL2028'}
        text={lang === 'ru' ? text : text_en}
      />
      <PaidServices />
    </div>
  )
}
