import React, { useEffect } from 'react'

import Paper from '@mui/material/Paper/Paper'
import { useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick'

import { useElementWidth } from '../../../../hooks/useElementWidth'

import style from './Room.module.scss'

import { mainPageTC, roomsTC } from 'api/bll/adminReduser'
import { oneRoomTC } from 'api/bll/roomReduser'
import { HomeRoom } from 'components/c2-Body/b1-Home/h3-HomeRooms/HomeRoom/HomeRoom'
import { AdditionalServices } from 'components/c2-Body/b2-AboutUs/a2-AdditionalServices/AdditionalServices'
import { HeaderBlock } from 'components/common/HeaderBlock/HeaderBlock'
import {
  SampleNextArrow,
  SampleNextArrowInside,
  SamplePrevArrow,
  SamplePrevArrowInside,
} from 'components/common/Slider/SliderArrows'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { language } from 'utils/selectors'

export const Room = () => {
  let { id } = useParams()
  const lang = useAppSelector(language)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const windowInnerWidth = window.innerWidth
  const [ref, width] = useElementWidth()
  const slidesShowFn = () => {
    let slidesShow = 4

    if (windowInnerWidth <= 1600) {
      slidesShow = 3
    }
    if (windowInnerWidth <= 1280) {
      slidesShow = 2
    }
    if (windowInnerWidth <= 768) {
      slidesShow = 1
    }

    return slidesShow
  }

  useEffect(() => {
    dispatch(oneRoomTC(Number(id)))
  }, [id])

  useEffect(() => {
    dispatch(roomsTC())
  }, [])

  useEffect(() => {
    dispatch(mainPageTC())
  }, [])

  const room = useAppSelector(state => state.room.room)
  const arrRooms = useAppSelector(state => state.admin.rooms)
  const mainPage = useAppSelector(state => state.admin.mainPage)

  const back = () => {
    window.scrollTo(0, 0)
    navigate('/rooms')
  }

  const settings1 = {
    infinite: true,
    speed: 2000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrowInside />,
    prevArrow: <SamplePrevArrowInside />,
    autoplay: true,
  }
  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesShowFn(),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <>
      <HeaderBlock
        title={lang === 'ru' ? room.title : room.title_en}
        after={'#f3b246'}
        width={'20%'}
      />
      <Paper
        style={{
          width: '90%',
          maxWidth: '800px',
          margin: '40px auto 60px',
        }}
        square
        elevation={9}
      >
        <Slider {...settings1}>
          {room.photos &&
            room.photos.map((photo, index) => {
              return (
                <div key={index} className={style.slide}>
                  <img src={photo} alt="" />
                </div>
              )
            })}
        </Slider>
        <div className={style.container}>
          <div className={style.container__header}>
            <h1 className={style.container__header_title}>
              {lang === 'ru' ? room.title : room.title_en}
            </h1>
            {(lang === 'ru' ? room.stuff : room.stuff_en).map((stuff, index) => {
              return <p key={index} className={style.container__header_text}>{`- ${stuff}`}</p>
            })}
            <p className={style.container__header_text}>
              {lang === 'ru' ? room.description : room.description_en}
            </p>
          </div>
          <div className={style.container__buttons}>
            <button className={style.container__buttons_back} onClick={back}>
              {lang === 'ru' ? 'Назад' : 'Back'}
            </button>
            <form
              action={lang === 'ru' ? mainPage.book_link : mainPage.book_link_en}
              target="_blank"
              className={style.container__buttons_reserve}
            >
              <button>{lang === 'ru' ? 'Забронировать' : 'Book now'}</button>
            </form>
          </div>
        </div>
      </Paper>
      <AdditionalServices />
      <HeaderBlock
        title={lang === 'ru' ? 'ДРУГИЕ НОМЕРА' : 'OTHER ROOMS'}
        before={'#f3b246'}
        width={'20%'}
      />
      <div ref={ref} className={style.homeRoomsContainer}>
        <Slider {...settings2}>
          {arrRooms.map((room, index) => (
            <HomeRoom key={room.id} room={room} />
          ))}
        </Slider>
      </div>
    </>
  )
}
